<template>
  <v-layout v-if="_.size(detail) > 0" row wrap align-space-around justify-center fill-height>
    <v-flex xs12 lg12 xl12 class="ubicacion">
      <v-card>
        <titleCard title="Ubicación del pedido" :subtitle="`Corregir ubicacion si es necesario`"></titleCard>
        <v-divider></v-divider>
        <v-layout wrap class="px-4 py-2">
          <v-flex xs12>
            <GmapAutocomplete
              placeholder="Ubicación"
              class="input-search"
              style="width:100%;"
              :value="addressComplete"
              @place_changed="setPlace"
            >
              >
            </GmapAutocomplete>
            <p class="grey--text text--darken-1 ma-0">Datos adicionales: {{ this.detail.shipping.more }}</p>
          </v-flex>
          <v-flex xs6>
            <v-switch
              v-model="notify"
              class="ma-0"
              hide-details
              color="accent"
              :label="
                `Notificar
                cliente`
              "
              :value="1"
              right
            ></v-switch>
          </v-flex>
          <v-flex xs6 class="text-xs-right">
            <v-btn class="ma-0" :loading="loading" :disabled="loading" color="primary" depressed @click="onSave">
              Actualizar
            </v-btn>
          </v-flex>
        </v-layout>
        <div class="gmap">
          <gmap-map
            id="map"
            ref="myMap"
            :center="center"
            :zoom="zoom"
            :options="options"
            :style="`width:100%;height:${windowHeight}px;`"
          >
            <GmapMarker :position="latLngOrder" :clickable="false" :draggable="true" @dragend="dragend" />
          </gmap-map>
        </div>
      </v-card>
    </v-flex>
  </v-layout>
</template>

<script>
import { ORDER } from '../../config'
import titleCard from '../useful/titleCard.vue'

export default {
  name: 'Message',
  components: { titleCard },
  props: ['order', 'getOrder'],
  data() {
    return {
      vm: {
        searchPlace: '',
        location: {}
      },
      windowHeight: window.innerHeight - 420,
      loading: false,
      notify: 1,
      detail: {},
      latLngOrder: {},
      center: { lat: -33.4262, lng: -70.6429 },
      place: {},
      zoom: 10,
      options: {
        styles: [],
        mapTypeControl: false,
        streetViewControl: false,
        fullscreenControl: false
      }
    }
  },
  computed: {
    addressComplete() {
      return `${this.detail.shipping.address} ${this.detail.shipping.address_number}, ${this.$options.filters.toHTML(
        this.detail.shipping.state
      )}`
    },
    location() {
      return `${this.detail.shipping.address} ${this.detail.shipping.address_number}, ${this.detail.shipping.state}`
    }
  },
  watch: {
    order() {
      this.detail = this.order
      this.setLatLngOrder({
        lat: this.detail.shipping.latitude,
        lng: this.detail.shipping.longitude
      })
    },
    windowHeight(newHeight, oldHeight) {
      this.txt = `it changed to ${newHeight} from ${oldHeight}`
    }
  },
  mounted() {
    this.detail = this.order
    if (this.detail.shipping !== undefined) {
      this.setLatLngOrder({
        lat: this.detail.shipping.latitude,
        lng: this.detail.shipping.longitude
      })
    }
    this.$nextTick(() => {
      window.addEventListener('resize', () => {
        this.windowHeight = window.innerHeight - 250
      })
    })
    this.$store.dispatch('updateTitle', `Ubicación - Pedido ${this.order.id}`)
  },
  methods: {
    async onSave() {
      try {
        this.loading = true
        await this.$http.post(`${ORDER}/${this.detail.id}/location`, {
          latlng: this.latLngOrder,
          notify: this.notify
        })
        this.loading = false
        this.$store.dispatch('changeSnack', { active: true, text: 'Ubicacion actualizada' })
        this.getOrder()
      } catch (error) {
        this.$store.dispatch('changeSnack', { active: true, text: 'Ocurrio un error, intente nuevamente' })
      }
    },
    onClickMap() {
      // eslint-disable-next-line no-undef
      const trafficLayer = new google.maps.TrafficLayer()
      trafficLayer.setMap(this.$refs.myMap.$mapObject)
    },
    setLatLngOrder(position) {
      this.latLngOrder = position
      this.center = position
      this.zoom = 16
    },
    setPlace(e) {
      const position = {
        lat: e.geometry.location.lat(),
        lng: e.geometry.location.lng()
      }
      this.setLatLngOrder(position)
    },
    dragend(e) {
      const position = {
        lat: e.latLng.lat(),
        lng: e.latLng.lng()
      }
      this.setLatLngOrder(position)
    }
  }
}
</script>

<style>
.gmap {
  position: relative;
}
.input-search {
  outline: none;
  padding: 12px 12px;
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-radius: 3px !important;
}
.input-search:focus {
  border: 1px solid #f44336;
}
.input-search::placeholder {
  font-style: 'italic';
  color: rgb(143, 143, 143);
  padding: 10px 2px 10px 2px;
}
.map-icon-label .map-icon {
  font-size: 24px;
  color: #ffffff;
  line-height: 48px;
  text-align: center;
  white-space: nowrap;
}
.label {
  background: #000;
}
</style>
